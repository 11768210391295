//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



import List from "./component/List";
import CoinSelect from "./component/CoinSelect";
import NetworkSelect from "./component/NetworkSelect";
import Confirmation from "./component/Confirmation";
import iconDown from "../../assets/img/icon-down.png";
import iconUp from "../../assets/img/icon-up.png";
import NftSelect from "./component/NftSelect.vue";
import NftNetworkSelect from "./component/NftNetworkSelect.vue";
import {
    getLayer2Balance,
    goWithdraw,
    getSubmitTokens,
    getHistoryTransaction,
    getAddress,
    getSignleSymbolPrice,
    getRestFee,
    fetchNftList,
    // 新版2层中心化相关
    l2CommonTransaction,
    l2NftTransaction,
    l2AccountIsActive,
    l2AccountActive,
    transferUnavailable,
} from "../../api/wallet/index";
import securityVerif from "../../components/securityVerif.vue";
import { ethers, BigNumber } from "ethers";
import {
    onEvents,
    emitEvents,
    isUserCenter,
    emitLoading,
    getWalletCoinFee,
    accAddSub,
    getChainId,
    getWalletNetWorkConfig,
    getChainLayer2Config,
    isEthAddress,
    getExplorer,
    parseNFTData,
    getL2NetworkList,
    formatArrStringNum,
    filterL2Balance,
    formatTokenCommify,
    formatFiatCommify,
} from "../../utils/index";
import NetworkTab from "./component/NetworkTab";
import content_hashUtils from "../../utils/content-hash";
import { uploadFile, uploadJson, IPFS_PREVIEW_URL } from "../../utils/ipfs.js";
import axios from "axios";
import ChainSelect from "./component/ChainSelect.vue";
import TokenSelect from "./component/TokenSelect.vue";

import wallet from '../../plugin/Wallet/walletUtil';
import * as VE from "@vigo.technology/v-engine";

let firstInput = false;
export default {
    name: 'Send',
    components: {
		ChainSelect,
        List,
        CoinSelect,
        Confirmation,
        securityVerif,
        //NetworkTab,
        NetworkSelect,
        NftSelect,
        NftNetworkSelect,
        TokenSelect,
    },
    data() {
        return {
            tabId: "token",
            showActive: false,
            feeToken: '',
            canWithdrawal: false,
            showToast: false,
            submitError: null,
            clipboard: "",
            viewAllShow: false,
            nftViewAllShow: false,
            confirmation: {},
            symbol: "",
            countTime: 0,
            curNotice: "",
            bread: [
                {
                    path: "/wallet",
                    name: this.$t("wallet_common_wallet"),
                },
                {
                    path: "/wallet/transfer",
                    name: this.$t("wallet_common_send_l2"),
                },
            ],
            CoinSelectShow: false,
            symbol: null,
            coinValue: {
                name: "Ethereum",
                symbol: "",
            },
            totalBalance: "",
            balance: "",
            balanceWei: "0",
            lockedBalance: "",
            isTokenAvailble: true,
            columns: [
                {
                    title: this.$t("wallet_common_date"),
                    key: "time",
                    dataIndex: "time",
                    width: 210
                },
                {
                    title: this.$t("wallet_withdraw_coin"),
                    key: "symbol",
                    // width: 80,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h(
                            "span",
                            {},
                            `${record.symbol}`
                            // `${params.row.symbol}-${params.row.network_name}`
                        );
                    },
                },
                {
                    title: this.$t("wallet_common_amount"),
                    key: "amount",
                    // width: 110,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h(
                            "span",
                            {},
                            `${record.action_symbol} ${record.amount}`
                        );
                    },
                },
                {
                    title: this.$t("wallet_common_status"),
                    key: "status",
                    // width: 120,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let st_completed = this.$t("wallet_history_completed");
                        let st_processing = this.$t("wallet_history_processing");
                        if (record.status == 'Completed') {
                            return h("span", {}, st_completed);
                        } else if (record.status == 'Processing') {
                            let netArr = record.network_confirmation.split("/");
                            let start = netArr.shift();
                            let end = "/ " + netArr.shift();
                            return h(
                                "div",
                                {
                                    class: {
                                        status: true,
                                    },
                                },
                                [
                                    h("p", {}, st_processing),
                                    h(
                                        "p",
                                        {
                                            class: {
                                                processing: true,
                                            },
                                        },
                                        [
                                            h("span", "( "),
                                            h(
                                                "span",
                                                {
                                                    class: {
                                                        already: true,
                                                    },
                                                },
                                                `${start} `
                                            ),
                                            h("span", `${end} )`),
                                        ]
                                    ),
                                ]
                            );
                        } else {
                            return h("span", {}, record.status);
                        }
                    },
                },
                {
                    title: this.$t("wallet_common_information"),
                    key: "key5",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let st_address = this.$t("wallet_common_address");
                        let st_txId = this.$t("wallet_history_txid");
                        let st_withdraw = this.$t("wallet_common_withdraw");
                        let st_transactionFee = this.$t("wallet_deposit_transaction_fee");
                        if (record.expand) {
                            let btns = [
                                h(
                                    "p",
                                    {
                                        class: {
                                            action: true,
                                        },
                                    },
                                    [
                                        h("span", {}, st_address),
                                        h(
                                            "span",
                                            {
                                                // style: {
                                                // 	"white-space": "nowrap",
                                                // },
                                            },
                                            record.address
                                        ),
                                    ]
                                ),
                            ];

                            if (record.txid) {
                                let txid = record.txid.indexOf("sync-tx:") >= 0 ? record.txid.substring(8) : record.txid;
                                const url = this.blockExplorerUrl + `tx/${txid}`;
                                btns.push(
                                    h(
                                        "p",
                                        {
                                            class: {
                                                action: true,
                                            },
                                        },
                                        [
                                            h(
                                                "a",
                                                {
                                                    attrs: {
                                                        href: url,
                                                        target: "_target",
                                                    },
                                                    style: {
                                                        lineHeight: "1em",
                                                        display: "flex",
                                                    },
                                                },
                                                [
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                display:
                                                                    "inline-block",
                                                            },
                                                        },
                                                        this.$t(
                                                            "wallet_history_txid"
                                                        ) + ":"
                                                    ),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                display: "inline-block",
                                                                lineSize: "fit-content",
                                                                wordBreak: "break-word"
                                                            },
                                                        },
                                                        [
                                                            record.txid,
                                                            h("img", {
                                                                style: {
                                                                    display:
                                                                        "inline-block",
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    verticalAlign:
                                                                        "-4px",
                                                                    marginLeft:
                                                                        ".2em",
                                                                },
                                                                attrs: {
                                                                    src: require("../../assets/img/share-link.png"),
                                                                },
                                                            }),
                                                        ]
                                                    ),
                                                ]
                                            ),
                                        ]
                                    )
                                );
                            }
                            if (record.transaction_fee) {
                                btns.push(
                                    h(
                                        "p",
                                        {
                                            class: {
                                                action: true,
                                            },
                                        },
                                        [
                                            h(
                                                "span",
                                                {},
                                                this.$t(
                                                    "wallet_deposit_transaction_fee"
                                                ) + ":"
                                            ),
                                            h(
                                                "span",
                                                {},
                                                record.transaction_fee
                                            ),
                                        ]
                                    )
                                );
                            }
                            if (record.gas_fee) {
                                btns.push(
                                    h(
                                        "p",
                                        {
                                            class: {
                                                action: true,
                                            },
                                        },
                                        [
                                            h(
                                                "span",
                                                {},
                                                this.$t(
                                                    "wallet_history_swap_fee"
                                                ) + ":"
                                            ),
                                            h("span", {}, record.gas_fee),
                                        ]
                                    )
                                );
                            }
                            return h("div", {}, btns);
                        } else {
                            return h("div", [
                                h(
                                    "p",
                                    {
                                        class: {
                                            action: true,
                                        },
                                    },
                                    [
                                        h("span", {}, st_address),
                                        h(
                                            "span",
                                            {
                                                // style: {
                                                // 	"white-space": "nowrap",
                                                // },
                                            },
                                            record.address
                                        ),
                                    ]
                                ),
                            ]);
                        }
                    },
                },
                {
                    title: this.$t("wallet_common_action"),
                    key: "",
                    width: 100,
                    align: "center",
                    customRender: (text, record, index) => {
                        let h = this.$createElement
                        let copyFn = this.copy;
                        let icon = record.expand ? iconUp : iconDown;
                        let st_withdraw = this.$t("wallet_common_withdraw");
                        let st_auditFailure = this.$t("wallet_history_audit_failure");
                        let btn = h("img", {
                            style: {
                                cursor: "pointer",
                            },
                            attrs: {
                                width: "20px",
                                height: "20px",
                                src: icon,
                            },
                            on: {
                                click: () => {
                                    this.data[index].expand = !this.data[index].expand;
                                },
                            },
                        });
                        let copy = h(
                            "div",
                            {
                                class: {
                                    copy: true,
                                },
                                on: {
                                    click: () => {
                                        this.clipboard = record.txid || "";
                                        this.$nextTick(function () {
                                            this.$refs.btn.click();
                                        });
                                    },
                                },
                            },
                            this.$t("wallet_common_copy")
                        );
                        let check = h(
                            "div",
                            {
                                class: {
                                    copy: true,
                                },
                                on: {
                                    click: () => {
                                        if (this.needTwoFA) {
                                            this.showToast = true;
                                        } else {
                                            this.getSubmitTokens({});
                                        }
                                    },
                                },
                            },
                            this.$t("wallet_common_check")
                        );
                        let btns = [btn];
                        if (record.expand) {
                            if (record.txid) {
                                btns.push(copy);
                            }
                            if (
                                record.type == st_withdraw &&
                                record.status == st_auditFailure
                            ) {
                                btns.push(check);
                            }
                        }
                        return h("div", btns);
                    },
                },
            ],
            nft_columns: [
                {
                    title: this.$t("wallet_common_date"),
                    key: "time",
                    dataIndex: "time",
                    width: 210,
                },
                {
                    title: this.$t("wallet_withdraw_coin"),
                    key: "symbol",
                    width: 80,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h(
                            "span",
                            {},
                            `${record.symbol}`
                            // `${params.row.symbol}-${params.row.network_name}`
                        );
                    },
                },
                {
                    title: this.$t("wallet_common_amount"),
                    key: "amount",
                    width: 100,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        return h(
                            "span",
                            {},
                            `${record.action_symbol} ${record.amount}`
                        );
                    },
                },
                {
                    title: this.$t("wallet_common_status"),
                    key: "status",
                    width: 120,
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let st_completed = this.$t("wallet_history_completed");
                        let st_processing = this.$t(
                            "wallet_history_processing"
                        );
                        if (record.status == st_completed) {
                            return h("span", {}, st_completed);
                        } else if (record.status == st_processing) {
                            let netArr = record.network_confirmation.split("/");
                            let start = netArr.shift();
                            let end = "/ " + netArr.shift();
                            return h(
                                "div",
                                {
                                    class: {
                                        status: true,
                                    },
                                },
                                [
                                    h("p", {}, st_processing),
                                    h(
                                        "p",
                                        {
                                            class: {
                                                processing: true,
                                            },
                                        },
                                        [
                                            h("span", "( "),
                                            h(
                                                "span",
                                                {
                                                    class: {
                                                        already: true,
                                                    },
                                                },
                                                `${start} `
                                            ),
                                            h("span", `${end} )`),
                                        ]
                                    ),
                                ]
                            );
                        } else {
                            return h("span", {}, record.status);
                        }
                    },
                },
                {
                    title: this.$t("wallet_common_information"),
                    key: "key5",
                    customRender: (text, record) => {
                        let h = this.$createElement
                        let st_address = this.$t("wallet_common_address");
                        let st_txId = this.$t("wallet_history_txid");
                        let st_withdraw = this.$t("wallet_common_withdraw");
                        let st_transactionFee = this.$t("wallet_deposit_transaction_fee");
                        if (record.expand) {
                            let btns = [
                                h(
                                    "p",
                                    {
                                        class: {
                                            action: true,
                                        },
                                    },
                                    [
                                        h("span", {}, st_address),
                                        h(
                                            "span",
                                            {
                                                // style: {
                                                // 	"white-space": "nowrap",
                                                // },
                                            },
                                            record.address
                                        ),
                                    ]
                                ),
                            ];

                            if (record.txid) {
                                let txid = record.txid.indexOf("sync-tx:") >= 0 ? record.txid.substring(8) : record.txid;
                                const url = this.blockExplorerUrl + `tx/${txid}`;
                                btns.push(
                                    h(
                                        "p",
                                        {
                                            class: {
                                                action: true,
                                            },
                                        },
                                        [
                                            h(
                                                "a",
                                                {
                                                    attrs: {
                                                        href: url,
                                                        target: "_target",
                                                    },
                                                    style: {
                                                        lineHeight: "1em",
                                                        display: "flex",
                                                    },
                                                },
                                                [
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                display:
                                                                    "inline-block",
                                                            },
                                                        },
                                                        this.$t(
                                                            "wallet_history_txid"
                                                        ) + ":"
                                                    ),
                                                    h(
                                                        "span",
                                                        {
                                                            style: {
                                                                display: "inline-block",
                                                                lineSize: "fit-content",
                                                                wordBreak: "break-word"
                                                            },
                                                        },
                                                        [
                                                            record.txid,
                                                            h("img", {
                                                                style: {
                                                                    display:
                                                                        "inline-block",
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    verticalAlign:
                                                                        "-4px",
                                                                    marginLeft:
                                                                        ".2em",
                                                                },
                                                                attrs: {
                                                                    src: require("../../assets/img/share-link.png"),
                                                                },
                                                            }),
                                                        ]
                                                    ),
                                                ]
                                            ),
                                        ]
                                    )
                                );
                            }
                            if (record.transaction_fee) {
                                btns.push(
                                    h(
                                        "p",
                                        {
                                            class: {
                                                action: true,
                                            },
                                        },
                                        [
                                            h(
                                                "span",
                                                {},
                                                this.$t(
                                                    "wallet_deposit_transaction_fee"
                                                ) + ":"
                                            ),
                                            h(
                                                "span",
                                                {},
                                                record.transaction_fee
                                            ),
                                        ]
                                    )
                                );
                            }
                            if (record.gas_fee) {
                                btns.push(
                                    h(
                                        "p",
                                        {
                                            class: {
                                                action: true,
                                            },
                                        },
                                        [
                                            h(
                                                "span",
                                                {},
                                                this.$t(
                                                    "wallet_history_swap_fee"
                                                ) + ":"
                                            ),
                                            h("span", {}, record.gas_fee),
                                        ]
                                    )
                                );
                            }
                            return h("div", {}, btns);
                        } else {
                            return h("div", [
                                h(
                                    "p",
                                    {
                                        class: {
                                            action: true,
                                        },
                                    },
                                    [
                                        h("span", {}, st_address),
                                        h(
                                            "span",
                                            {
                                                // style: {
                                                // 	"white-space": "nowrap",
                                                // },
                                            },
                                            record.address
                                        )
                                    ]
                                )
                            ]);
                        }
                    },
                },
                {
                    title: this.$t("wallet_common_action"),
                    key: "",
                    width: 100,
                    align: "center",
                    customRender: (text, record, index) => {
                        let h = this.$createElement
                        let copyFn = this.copy;
                        let icon = record.expand ? iconUp : iconDown;
                        let st_withdraw = this.$t("wallet_common_withdraw");
                        let st_auditFailure = this.$t("wallet_history_audit_failure");
                        let btn = h("img", {
                            style: {
                                cursor: "pointer",
                            },
                            attrs: {
                                width: "20px",
                                height: "20px",
                                src: icon,
                            },
                            on: {
                                click: () => {
                                    this.nftTransferData[index].expand = !this.nftTransferData[index].expand;
                                },
                            },
                        });
                        let copy = h(
                            "div",
                            {
                                class: {
                                    copy: true,
                                },
                                on: {
                                    click: () => {
                                        this.clipboard = record.txid || "";
                                        this.$nextTick(function () {
                                            this.$refs.btn.click();
                                        });
                                    },
                                },
                            },
                            this.$t("wallet_common_copy")
                        );
                        let check = h(
                            "div",
                            {
                                class: {
                                    copy: true,
                                },
                                on: {
                                    click: () => {
                                        if (this.needTwoFA) {
                                            this.showToast = true;
                                        } else {
                                            this.getSubmitTokens({});
                                        }
                                    },
                                },
                            },
                            this.$t("wallet_common_check")
                        );
                        let btns = [btn];
                        if (record.expand) {
                            if (record.txid) {
                                btns.push(copy);
                            }
                            if (
                                record.type == st_withdraw &&
                                record.status == st_auditFailure
                            ) {
                                btns.push(check);
                            }
                        }
                        return h("div", btns);
                    },
                },
            ],
            data: [],
            nftTransferData:[],
            depositData: [],

            curAddress: "",
            custAddressShow: false,
            preAddress: sessionStorage.getItem("WITHDRAWADDRESS"),
            custAddressError: null,
            timeOut: null,

            curAmount: null,
            amountError: null,
            submitDis: true,
            transaction_fee: "",
            transactionFeeWei: "",
            loading: false,
            transaction_fee_value: 0,
            decimals: 0,
            amountDecimals: 0,
            userCenter: isUserCenter(),
            balance_loading: false,
            balance_list_loading: false,
            nft_list_loading:false,
            transferAddrList: [],
            chainId: null,
            dealAll: false,
            //nft
            nftNetworkList: [],
            nftNetworkExpand: false,
            nftNetwork: "",
            nftLoading: false,
            nftTransferLoading:false,
            nftExpand: false,
            nftId: "",
            nftSelectShow: false,
            nftList: [],
            nftCoinValue: {
                name: "ETH",
                symbol: "",
            },
            nftFee: {
                balance: null,
                fee: null,
                feeSymbol: null,
                token: {},
                price: null,
            },
            isShowNft: false,
            nftFeeError: false,
            nftAmount: '',
            accountBalance: {},
            popTime: this.$store.state.popShowTime || 2,
        };
    },
    filters: {
        formatWei(num, decimal=18) {
            if (!num || !decimal) {
                return 0;
            }
            return ethers.utils.formatUnits(num.toString(), decimal);
        },
        formatToUSD(num,tokenPrice){
            if(!num||!tokenPrice){
                return 0;
            }
            num = Number(num);
            const n = num*tokenPrice
            return formatArrStringNum(n);

        },


    },
    computed: {
		// account() {
		// 	return this.$store.state.wallet.wallet.address;
		// },
        tokens() {
            return this.$store.getters.chainLayerToken(`${this.chainId}-2`) || [];
        },
        account() {
			return this.$store.getters.account || '';
		},
		walletNetworks() {
			return this.$store.getters.walletNetworks;
		},
        currency() {
            return this.$store.state.user.currency;
        },
        // 判断是否需要2FA验证，可以在后台配置
        needTwoFA() {
            return this.$store.getters.needTwoFA;
        },
        // 链的数据，统一使用ChainSelect组件
        netWorkList() {
            let networkList = this.$store.getters.walletNetworks || []
            return networkList
        },
        layer1NetworkList() {
            return this.$store.getters.layer1NetworkList;
        },
        blockExplorerUrl() {
            // 浏览器地址获取，如果后台有配置就用配置，没有则从网络配置中获取。
            const privateUrl = window.site_config.block_explorer_url;
            if (privateUrl) {
                return privateUrl.endsWith("/")
                    ? `${privateUrl}${this.chainId}/L2/`
                    : `${privateUrl}/${this.chainId}/L2/`;
            } else {
                const url = this.$store.getters.filterNetworkItem(
                    this.chainId,
                    "L2"
                ).blockExplorerUrl;
                return url.endsWith("/") ? url : url + "/";
            }
        },
        supportNFT() {
            return this.$store.getters.supportNFT || this.isShowNft;
        },
        // 判断是否中心化
        accountIsCustody() {
            return this.$store.getters.accountIsCustody;
        },
        maxAmountWei() {
            let fee = this.isTokenAvailble ? this.transactionFeeWei : 0
            return BigNumber.from(this.balanceWei || "0").sub(fee || "0");
        },
        nftDetail() {
            let nftDetil = this.nftList.find(v => v.id == this.nftId) || {}
            return nftDetil
        },
        nftBalanceAmount() {
            return this.nftId && this.accountBalance[this.nftId] || 0
        },
        isNft1155() {
            let nftDetil = this.nftDetail || {}
            return  nftDetil && nftDetil.nft_data && nftDetil.nft_data.amount > 1
        },
        nftErrorText() {
            return this.$t('wallet_transfer_message_error_insufficientError')
        },
        isNft1155Disable() {
            return this.isNft1155 ? (!this.nftAmount || Number(this.nftAmount) > Number(this.nftBalanceAmount)) : false
        }
    },
    watch: {
        transaction_fee(val) {
            if (val) {
                this.getSignleSymbolPrice();
            }
        },
        curAmount(value) {
            if (!firstInput && value.length > 0) {
                firstInput = true;
            }
            if (firstInput) {
                this.checkAmount();
            }
        },
        nftId() {
            this.getBalance()
        }
    },
    created() {
        this.$nextTick(() => {
            onEvents("refresh", (events) => {
                if (!!~events.indexOf("currency")) {
                    this.getTokenFee(this.coinValue.symbol);
                }
            });
        });
        // this.init();
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
			const cacheTokenSymbol = sessionStorage.getItem("TokenSymbol");
			const { symbol, chainId, address, tab, nftTaskConfig } = this.$route.query;
            this.tabId = tab || "token";
			this.coinValue.symbol = symbol || cacheTokenSymbol;
            this.symbol = symbol || cacheTokenSymbol;
			// this.chainId = chainId || this.$store.state.wallet.walletChainId;
			this.chainId = Number(chainId || this.$store.state.chainId);
            this.curAddress = address;
            this.isShowNft = nftTaskConfig
            // this.getInfo();

            // this.$store.commit("setCheckDataChanId", this.chainId);

            if (this.supportNFT) {
                this.fetchNftList();
                this.getNftTxFee();
                // this.getTransferNFTFee();
            }
            this.fetchTokens();
        },
        async fetchTokens() {
            await this.$store.dispatch("getTokenByChainAndLayer", {chainLayer: `${this.chainId}-2`}).catch(e => []);
            if (!this.symbol) {
				this.symbol = this.tokens[0] && this.tokens[0].symbol;
                this.coinValue.symbol = this.symbol;
                this.coinValue = structuredClone(this.tokens[0]) || {};
			} else {
				// 检查选中的symbol是否在当前tokens中
				const symbols = this.tokens.map(item => item.symbol);
				if (!symbols.includes(this.symbol)) {
					this.symbol = this.tokens[0] && this.tokens[0].symbol;
                    this.coinValue = structuredClone(this.tokens[0]) || {};
				} else {
                    let coinValue = this.tokens.find(item => item.symbol == this.symbol);
                    this.coinValue = structuredClone(coinValue) || {};
                }
			}
            console.log('this.coinValue', this.coinValue)
			this.changeCoin(this.coinValue);
		},
        changeTab(key) {
            this.tabId = key;
            if(key==='nft'){
                this.getNftTransferHistory();
            }
        },
        changeCoin(item) {
            if (!item || !Object.keys(item).length) {
                return this.balance_list_loading = false;
            }
            this.coinValue = item;
            this.transaction_fee_value = formatFiatCommify(0, this.coinValue.show_scale);
            sessionStorage.setItem("TokenSymbol", this.coinValue.symbol);
            this.clearPagesCache();
            this.getInfo();
            this.getTokenFee(item.symbol);
            this.getAmoutDecimals();
            this.setSearchKey();
        },
        getAmoutDecimals() {
            this.amountDecimals = this.coinValue.show_scale;
        },
        // changeChain(chainId) {
        // 使用ChainSelect组件之后，组件内回调的是对象
        changeChain({chainId}) {
            this.amountError = null;
            this.confirmation = this.depositData.find(
                (item) => item.chainId == chainId
            );
            this.chainId = chainId;
            this.transaction_fee_value = formatFiatCommify(0, this.coinValue.show_scale);
            this.$store.commit("setCheckDataChanId", chainId);
            this.fetchTokens();
        },
        dealChangeWallet() {
            let chainId = getChainId() + "";
            if (this.chainId != chainId) {
                this.$bus.$emit("changeBridgeNetWork", {
                    chainId: this.chainId,
                });
                return false;
            } else {
                return true;
            }
        },
        async getFeeFunc(symbol = "ETH") {
            let config = getChainLayer2Config(this.chainId) || [];
            let params = {
                url: config.rest,
                tokenLike: symbol,
                txType: "Transfer",
                address: this.curAddress || this.account, //this.walletData.getAddress(),
            };
            this.resetFee();
            getRestFee(params)
                .then((res) => {
                    if (res.result) {
                        let fee = res.result.totalFee;
                        this.transactionFeeWei = VE.utils.closestPackableTransactionAmount(fee).toString();
                        this.transaction_fee = ethers.utils.formatUnits(
                            fee.toString(),
                            this.coinValue.decimals || 18
                        );
                        console.log(
                            "this.transaction_fee",
                            this.transactionFeeWei,
                            this.transaction_fee
                        );
                        this.checkAmount();
                    }
                })
                .catch((err) => {
                    this.resetFee();
                });
        },
        async getTokenFee(symbol = this.symbol) {
            this.isTokenAvailble = await this.$store.dispatch('isTokenAvailable', {chainId: this.chainId, symbol})
            console.log('this.isTokenAvailble', this.isTokenAvailble)
            if (this.isTokenAvailble) {
                this.getFeeFunc(symbol)
                this.feeToken = symbol
            } else {
                this.$store.dispatch("getL2Fee", {
                    chainId: this.chainId,
                    address: this.account,
                    txType: "Transfer"
                }).then(async (res) => {

                    console.log('getL2Fee:', res)
                    if (res) {
                        this.feeToken = res.symbol;
                        let feeTokenDetail = await this.$store.dispatch('getContratTokenDetailBySymbol', {chainId: this.chainId, symbol: this.feeToken})
                        let fee = res.totalFee;
                        this.transactionFeeWei = VE.utils.closestPackableTransactionAmount(fee).toString();
                        this.transaction_fee = ethers.utils.formatUnits(
                            fee.toString(),
                            feeTokenDetail.decimals || 18
                        );
                        console.log("this.transaction_fee",
                            this.transactionFeeWei,
                            this.transaction_fee
                        );
                        this.checkAmount();
                    } else { // 没有返回有余额的币种, 显示当前手续费
                        this.getFeeFunc(symbol)
                        this.feeToken = symbol
                    }
                })
                .catch((err) => {
                    console.log('err:', err)
                    this.resetFee();
                });
            }
        },
        async getSignleSymbolPrice() {
            let feeTokenDetai = await this.$store.dispatch('getContratTokenDetailBySymbol', {chainId: this.chainId, symbol: this.feeToken})
            getSignleSymbolPrice({
                fsym: this.feeToken,
                tsyms: this.currency.name,
            })
                .then((getFeeVal) => {
                    this.transaction_fee_value = formatFiatCommify(
                        this.transaction_fee * getFeeVal[this.currency.name]
                        , feeTokenDetai.show_scale
                    );
                })
                .catch((err) => {
                    console.log("err", err);
                    if (feeTokenDetai?.show_scale) {
                        this.transaction_fee_value = formatFiatCommify(0, feeTokenDetai.show_scale);
                    } else {
                        this.transaction_fee_value = formatFiatCommify(0);
                    }
                });
        },
        // address
        addressBlur(value) {
            if (value) {
                this.checkAddress(value);
            } else {
                this.custAddressShow = false;
                this.custAddressError = this.$t(
                    "wallet_withdraw_message_error_addressBlurError"
                );
            }
        },
        checkAddress(value) {
            this.curAddress = value;

            if (!isEthAddress(value)) {
                this.custAddressError = this.$t("wallet_invalid_address");
                return;
            }
            this.custAddressError = "";
            this.getTokenFee(this.coinValue.symbol);
            this.setsubmitDis();
            // checkAddress({address:value, network_id: this.chainId, verify_repeat:false}).then(res=>{
            //     this.preAddress = value;
            //     this.custAddressError = null;
            //     this.getFeeFunc()
            // },res=> {
            //     this.custAddressError = res.message;
            // }).finally(()=>{
            //     this.setsubmitDis()
            // })
        },
        queryAddress() {
            this.transferAddrList = this.$store.state.transferAddrList || [];
            if (this.transferAddrList.length) return;
            let params = Object.assign(
                {},
                {
                    network_id: this.chainId,
                }
            );
            getAddress(params).then((res) => {
                if (res.code == 200) {
                    let addrList =
                        res.data.map((v) => {
                            return v.address;
                        }) || [];
                    if (addrList.length) {
                        this.$store.commit("setTransferAddrList", addrList);
                        this.transferAddrList = addrList;
                    }
                }
            }).catch(e => e);
        },
        blurOrClick(e) {
            let nodeName = e.target.nodeName;
            this.timeOut && clearTimeout(this.timeOut);
            return (() => {
                this.timeOut = setTimeout(() => {
                    if (nodeName == "INPUT") {
                        this.custAddressShow = false;
                        this.addressBlur(e.target.value);
                    } else if (nodeName == "DIV") {
                        this.custAddressShow = false;
                        this.checkAddress(this.preAddress);
                    }
                }, 300);
            })();
        },
        adrFocus() {
            this.preAddress && (this.custAddressShow = true);
        },
        clearCurAddress() {
            this.curAddress = "";
            this.checkAddress();
        },
        // amount
        curAmountInput(e) {
            // let num = this.amountDecimals;
            // let reg = new RegExp(`^(\\d+)\\.(\\d{0,${num}}).*$`);
            // let value = e.target.value;
            // this.curAmount = value.replace(reg, "$1.$2");
            // e.target.value = this.curAmount;
            // this.dealAll = false
            let iptRegExp = /\d|\./g
            if (!iptRegExp.test(e.data) && e.inputType == 'insertText' || e.data== '.' && e.target.value.split('.').length > 2) {
                e.target.value = this.curAmount = this.curAmount?.slice(0, this.curAmount.length) || 0
                return
            }
            let value = e.target.value;
            let num = this.amountDecimals;
            let reg = new RegExp(`^(\\d+)\\.(\\d{0,${num}}).*$`);
            this.curAmount = value.replace(reg, "$1.$2");
            this.dealAll = false
        },
        checkAmount() {
            const decimals = this.coinValue.decimals;
            console.log('x', this.curAmount, decimals)
            if (!this.curAmount) {
                this.amountError = this.$t(
                    "wallet_withdraw_message_error_amountError"
                );
            } else if (!(Number(this.curAmount) > 0)) {
                this.amountError = this.$t(
                    "wallet_withdraw_message_error_invalid"
                );
            } else if (
                ethers.utils.parseUnits(this.curAmount, this.coinValue.decimals).gt(this.maxAmountWei) ||
                !(this.curAmount * 1)
            ) {
                this.amountError = this.$t(
                    "wallet_transfer_message_error_insufficientError"
                );
            } else {
                this.amountError = null;
            }

            this.setsubmitDis();
        },
        goAddress(routerName = "address") {
            this.$router.push({
                name: routerName,
                query: {
                    symbol: this.symbol,
                    network_id: this.chainId,
                    coin_name: this.coinValue.name,
                    network_name: this.confirmation.network_name,
                    router_name: this.$route.name,
                },
            });
        },
        checkParams(params) {
            if (!params.address) {
                this.curNotice = this.$t(
                    "wallet_transfer_message_error_addrIncorrect"
                );
                return 0;
            } else if (!params.amount) {
                this.curNotice = this.$t(
                    "wallet_transfer_message_error_amountIncorrect"
                );
                return 0;
            } else if (!params.network_id) {
                this.curNotice = this.$t(
                    "wallet_transfer_message_error_networdIncorrect"
                );
                return 0;
            } else {
                this.curNotice = "";
            }
        },
        setsubmitDis() {
            if (
                this.amountError
            ) {
                console.log('1')
                this.submitDis = true;
                return;
            }
            if (!this.canWithdrawal) {
                this.submitDis = true;
                return;
            }
            if (
                this.curAddress &&
                !this.custAddressError &&
                this.curAmount &&
                !this.amountError
            ) {
                this.submitDis = false;
            } else {
                this.submitDis = true;
            }
        },
        goSubmit() {
            if (this.submitDis || !this.transaction_fee || this.loading) {
                return;
            }
            if (!this.accountIsCustody) {
                const isCurrentChain = this.dealChangeWallet();
                if (!isCurrentChain) return;
            }
            let params = {
                address: this.curAddress,
                amount: this.curAmount,
                // 中心化的账号在confirmation没有transaction_fee
                fee: this.confirmation.transaction_fee || this.transaction_fee,
                coin: this.coinValue.symbol,
                // 中心化的账号在confirmation上是驼峰
                network_id: (this.confirmation.network_id || this.confirmation.networkId) || 1,
                network_name: this.confirmation.chainName,
                symbol: this.symbol,
            };
            if (!params.address) {
                this.curNotice = this.$t(
                    "wallet_transfer_message_error_addrIncorrect"
                );
                return 0;
            } else if (!params.amount || !params.amount * 1) {
                this.curNotice = this.$t(
                    "wallet_transfer_message_error_amountIncorrect"
                );
                return 0;
            } else if (!params.network_id) {
                this.curNotice = this.$t(
                    "wallet_transfer_message_error_networdIncorrect"
                );
                return 0;
            } else {
                this.curNotice = "";
            }
            this.loading = true;
            // 1、中心化和去中心化流程不一样，这里判断一下
            if (this.accountIsCustody) {
                // 中心化
                this.handleCustodySubmit(params);
            } else {
                // 去中心化
                if (!this.walletData.getSyncWallet()) {
                    this.walletData.init({
                        successFn: () => {
                            this.walletOrActive();
                            this.loading = false;
                        },
                        loading: () => {
                            this.loading = true;
                        },
                        loadingEnd: () => {
                            this.loading = false;
                        },
                    });
                } else {
                    this.walletOrActive();
                }
            }
        },
        // 激活2层账号（改为统一由loginWallet激活了，该方法暂时无用）
        async activeAccount() {
            // 1、中心化和去中心化流程不一样，这里判断一下
            if (this.accountIsCustody) {
                // 中心化2层账号激活
                const { data: submitToken } = await getSubmitTokens();
                try {
                    const res = await l2AccountActive({
                        chain_id: this.chainId,
                        token: this.coinValue.symbol,
                    }, submitToken);
                    if (res && res.is_active) {
                        this.showActive = false;
                    }
                } catch (error) {
                    console.log(error);
                    this.$message.error({
                        content: error.messsage
                    });
                }
            } else {
                // 非中心账号激活
                let syncWallet = this.walletData.getSyncWallet();
                const changePubkey = await syncWallet.setSigningKey({
                    ethAuthType: "ECDSA",
                    feeToken: "ETH",
                });
                await changePubkey.awaitReceipt();
                this.showActive = false;
            }
        },
        async isActive() {
            let syncWallet = this.walletData.getSyncWallet();
            let isActive = false;
            if (!(await this.$store.dispatch("isL2Active", {
                chainId: this.chainId,
                address: this.account && this.account.toLowerCase(),
                // type: "committed",
                // balanceType: "committed",
            }))) {
            // if (!(await syncWallet.isSigningKeySet())) {
                // if ((await syncWallet.getAccountId()) == undefined) {
                // 	this.$router.push({
                // 		path: "/walletconnect/deposit",
                // 	});
                // 	// throw new Error("Unknown account");
                // }
                isActive = false;
            } else {
                isActive = true;
            }
            return isActive;
        },
        async walletOrActive() {
            let activeAcount = await this.isActive();
            if (activeAcount) {
                this.decimals = this.coinValue.decimals;
                this.transferTransaction();
            } else {
                this.loading = false;

                let accountHash = await this.$store.dispatch('getL2AccountIdHash', {
                    chainId: this.chainId,
                    address: this.account && this.account.toLowerCase()
                })
                // console.log('accountHash:', accountHash)
                this.$bus.$emit('activeLayer2Account',{pubKeyHash: accountHash.pubKeyHash})
                // emitEvents("linkLogin", { type: "active_account", fn: () => {
                //     this.getBalance()
                // }});
            }
        },
        // todo:支付手续费
        async transferTransaction() {
            this.loading = true;
            emitLoading({
                loading: true,
                fn: () => {
                    this.loading = false;
                },
            });
            let params = {
                address: this.curAddress && this.curAddress.toLowerCase(),
                token: this.symbol,
                amount: ethers.utils.parseUnits(this.curAmount, this.coinValue.decimals).toString(),
                fee: this.transactionFeeWei,
                feeToken: this.feeToken
            };
            let feeTokenDetai = {}
            try {
                if (!this.isTokenAvailble) {
                    feeTokenDetai = await this.$store.dispatch('getContratTokenDetailBySymbol', {chainId: this.chainId, symbol: this.feeToken})
                }
                getWalletCoinFee(
                    Object.assign({ type: "Transfer" }, params, {token: (this.isTokenAvailble ? this.symbol : this.feeToken)}), (fee) => {
                        console.log('getWalletCoinFee fee:', fee, fee.toString());
                        fee = VE.utils.closestPackableTransactionFee(fee);
                        // 页面显示用。
                        this.transaction_fee = ethers.utils.formatUnits(
                            fee.toString(),
                            this.isTokenAvailble ? this.coinValue.decimals : feeTokenDetai.decimals
                        );
                        // 更新fee
                        params.fee = fee.toString();
                        params.unAvailabel = !this.isTokenAvailble
                        if (this.dealAll) {
                            if (!this.isTokenAvailble) {
                                fee = 0
                            }
                            let amountWei = BigNumber.from(this.balanceWei).sub(fee);
                            amountWei = VE.utils.closestPackableTransactionAmount(amountWei).toString();

                            this.curAmount = ethers.utils.formatUnits(
                                amountWei,
                                this.coinValue.decimals
                            )
                            console.log("this.curAmount:", this.curAmount);
                            params.amount = amountWei;
                            this.dealAll = false
                        }
                        // params.fee = "2340000000000";
                        emitLoading({
                            loading: true,
                            fn: () => {
                                this.loading = false;
                            },
                        });
                        console.log("params:", params);
                        this.walletUtil
                            .transfer_old(params, async (transfer_res) => {
                                console.log('transfer_res:', transfer_res)
                                if (!this.isTokenAvailble) {
                                    let config = getChainLayer2Config(this.chainId) || [];
                                    let transferRes = await transferUnavailable(Object.assign({
                                        url: config.rest
                                    }, transfer_res))
                                    // console.log('transferRes:', transferRes)
                                    this.curAmount = null;
                                    if (transferRes.status !== 'success') {
                                        let message = transferRes.error && transferRes.error.message || ''
                                        console.log('transfer-res:', message)
                                        this.$message.error({
                                            content: this.$t("wallet_transfer_fail")
                                        });
                                        this.loading = false;
                                        emitLoading({
                                            loading: false,
                                        });
                                        return
                                    }
                                }
                                this.curAmount = null;
                                this.$message.success({
                                    content: this.$t("wallet_transfer_success")
                                });
                                try {
                                    setTimeout(() => {
                                        this.getInfo();
                                    }, 500);
                                } catch (error) {
                                    console.log("getInfo", error);
                                }
                            })
                            .catch((err) => {
                                console.log("err", err);
                                this.$message.error({
                                    content: this.$t("wallet_transfer_fail")
                                });
                                this.loading = false;
                                emitLoading({
                                    loading: false,
                                });
                            })
                            .finally((err) => {
                                this.loading = false;
                                emitLoading("linkLoading", {
                                    loading: false,
                                });
                            });
                    }
                );
            } catch (error) {}
        },

        // 判断中心化账号2层是否激活
        async isCustodyActive() {
            console.log("checkLayer2AccountIsActive");
            
            return this.$store.dispatch("getL2AccountIdHash", {
                chainId: this.chainId,
                address: this.account && this.account.toLowerCase(),
                // type: "committed",
                // balanceType: "committed",
            }).then((res) => {
                console.log('res:', res);
                if (res && res.pubKeyHash === "sync:0000000000000000000000000000000000000000") {
                    // 未激活，打开激活弹窗
                    this.$bus.$emit('activeLayer2Account', {
                        pubKeyHash: res.pubKeyHash,
                    });
                    this.loading = false;
                    this.nftTransferLoading = false;
                    return false;
                }
                return true;
            }).catch((err) => {
                this.$message.error({
                    content: err.message || 'Active Failures'
                });
            })
        },
        // 中心化钱包点击确认逻辑
        async handleCustodySubmit(params) {
            const isActive = await this.isCustodyActive();
            if (isActive) {
                // 1、已经激活
                // this.$store.commit('setConfirmFlag', true);
                // this.$store.commit('setSubmitInfo', params);
                this.submit();
            }
        },
        // 中心化账号，确认弹窗确认按钮点击
        submit() {
            // this.$store.state.confirmFlag = false;
            // 重置确认弹窗数据
            // this.$store.commit('setConfirmFlag', false);
            // this.$store.commit('setSubmitInfo', {});
            // 判断是否需要2FA验证
            // if (this.tabId == 'nft') {
            //     this.getSubmitTokens({});
            // } else {
            if (this.needTwoFA) {
                this.loading = false;
                this.nftTransferLoading = false;
                this.showToast = true;
            } else {
                this.getSubmitTokens({});
            }
            // }
        },
        toAll(type) {
            if (type == 'nft') {
                this.nftAmount = this.nftDetail?.neft_data?.amount || ''
                return
            }
            if (this.balance <= 0) {
                this.curAmount = 0;
                return;
            }
            let fee = this.isTokenAvailble ? this.transactionFeeWei : 0
            let maxAmountWei = BigNumber.from(this.balanceWei).sub(fee);
            maxAmountWei = VE.utils.closestPackableTransactionAmount(maxAmountWei);
            const decimals = this.coinValue.decimals;
            let curAmount;
            if (maxAmountWei.lte("0")) {
                curAmount  = 0;
            } else {
                curAmount = ethers.utils.formatUnits(maxAmountWei.toString(), decimals);
            }

            let reg = new RegExp(`^(\\d+)\\.(\\d{0,${decimals}}).*$`);
            this.curAmount = curAmount.toString().replace(reg, "$1.$2");
            this.checkAmount();
            this.dealAll = true;
        },
        toHistory() {
            this.$router.push({
                name: "history",
                query: {
                    transaction_type: "Send",
                    coin_name: this.coinValue.symbol,
                    chainId: this.chainId,
                    layer: "L2",
                    type: "ERC20",
                },
            });
        },
        getLayer1NetworkItem(chainId) {
            return this.layer1NetworkList.find(
                (item) => item.chainId === chainId
            );
        },
        getNetworkList(arr) {
            let netWorks = getWalletNetWorkConfig();
            // 融合基础网络列表数据
            netWorks = netWorks.map((item) => {
                return Object.assign(
                    {},
                    this.getLayer1NetworkItem(Number(item.chainId)),
                    item
                );
            });
            return netWorks;
        },
        async getInfo() {
            this.depositData = this.getNetworkList();
            if (this.chainId) {
                this.confirmation = this.depositData.find((v) => {
                    return v.chainId == this.chainId;
                });
            }
            this.symbol = this.coinValue.symbol;
            this.queryAddress();

            this.balance_loading = true;
            this.totalBalance = "";
            this.balance = "";
            this.lockedBalance = "";


            this.getBalance();
            await this.getHistory();
            if  (this.supportNFT) {
                this.getNftTransferHistory()
            }
            
        },
        async getHistory() {
            let address = this.account;//this.walletData.getAddress();
            let params = {
                page_no: 0,
                page_size: 10,
                coin_name: this.coinValue.symbol,
                transaction_type: "Transfer",
                transaction_status: "all",
                start_date: "",
                end_date: "",
                address,
                chain_id: this.chainId,
            };
            this.balance_list_loading = true;
            this.data = [];
            await getHistoryTransaction(params)
                .then((res) => {
                    if (res.code == 200) {
                        this.viewAllShow = res.data.total_number > 5;
                        let networkL2 = getExplorer(this.chainId * 1);
                        let networkL2Name = networkL2 + "_l2";
                        this.data = res.data.transaction_history_list.map(
                            (item) => {
                                item.networkL2Name = networkL2Name;
                                return Object.assign(item, { expand: false });
                            }
                        );
                        return;
                    }
                }).catch(e => e)
                .finally((fina) => {
                    this.balance_list_loading = false;
                });
        },
        getNftTransferHistory() {
            let address = this.account;//this.walletData.getAddress();
            let params = {
                page_no: 0,
                page_size: 10,
                coin_name: '',
                transaction_type: "TransferNFT",
                transaction_status: "all",
                start_date: "",
                end_date: "",
                address,
                chain_id: this.chainId,
            };
            this.nft_list_loading = true;
            this.nftTransferData = [];
            console.log('ggggggggg')
            getHistoryTransaction(params)
                .then((res) => {
                    console.log(res)
                    if (res.code == 200) {
                        this.nftViewAllShow = res.data.total_number > 5;
                        let networkL2 = getExplorer(this.chainId * 1);
                        let networkL2Name = networkL2 + "_l2";
                        this.nftTransferData = res.data.transaction_history_list.map(
                            (item) => {
                                item.networkL2Name = networkL2Name;
                                return Object.assign(item, { expand: false });
                            }
                        );
                    }
                }).catch(e => e)
                .finally((fina) => {
                    this.nft_list_loading = false;
                });
        },
        clearPagesCache() {
            // this.curAddress = "";
            this.custAddressError = null;
            this.curAmount = "";
            this.amountError = "";
        },
        copy() {
            this.$message.config({
                top: 290,
                duration: 10,
                closable: true,
            });
            this.$message.success({
                content: this.$t("wallet_common_copied"),
                duration: 3,
            });
        },
        getBalance() {
            let config = getChainLayer2Config(this.chainId) || [];
            let reqUrl = config.rest;
            let queryLayer2Param = {
                url: reqUrl,
                address: this.account,//this.walletData.getAddress(),
                type: "balances",
            };
            if (!queryLayer2Param.address) return;
            getLayer2Balance(queryLayer2Param)
                .then((res) => {
                    console.log('this.coinValue:', this.coinValue)
                    if (res.status == "success") {
                        const { decimals, show_scale: showScale, symbol } = this.coinValue;

                        const totalBalanceWei = filterL2Balance(res.result, 'committed', symbol) || "0";
                        const availableBalanceWei = filterL2Balance(res.result, 'available', symbol) || "0";
                        const lockedBalanceWei = filterL2Balance(res.result, 'locked', symbol) || "0";

                        this.totalBalance = formatTokenCommify(
                            ethers.utils.formatUnits(totalBalanceWei, decimals),
                            showScale
                        );
                        this.balanceWei = availableBalanceWei;
                        this.balance = formatTokenCommify(
                            ethers.utils.formatUnits(availableBalanceWei, decimals),
                            showScale
                        );
                        this.lockedBalance = formatTokenCommify(
                            ethers.utils.formatUnits(lockedBalanceWei, decimals),
                            showScale
                        );
                        this.accountBalance = res.result?.available?.balances || {}
                        this.canWithdrawal = true;
                    }
                }).catch(e => e)
                .finally(() => {
                    if (this.dealAll) this.toAll()
                    this.balance_loading = false;
                });
        },
        changeCode() {
            this.submitError = null;
        },
        getSubmitTokens(code) {
            this.loading = true;
            this.nftTransferLoading = true;
            this.showToast = false;
            getSubmitTokens().then(async (res) => {
                // 1、判断中心化和非中心化
                //  接口不一样
                if (this.accountIsCustody) {
                    // 中心化
                    const params = this.tabId == 'nft' ? {
                        ...code,
                        chain_id: this.chainId,
                        amount: this.nftAmount,
                        token_id: this.nftId,
                        to_address: this.curAddress,
                        fee_token: this.nftFee.feeSymbol,
                    } : {
                        ...code,
                        chain_id: this.chainId,
                        // 转换成wei
                        amount: ethers.utils.parseUnits(this.curAmount, this.coinValue.decimals).toString(),
                        token: this.coinValue.symbol,
                        to_address: this.curAddress,
                        fee_token: this.feeToken
                    }
                    console.log('params:', this.tabId, params)
                    let L2Transfer = this.tabId == 'nft' ? l2NftTransaction : l2CommonTransaction
                    // 2、调用转账接口
                    // https://wiki.everylink.ai/pages/viewpage.action?pageId=90406922
                    L2Transfer(params, res.data).then(res => {
                        this.$message.success({
                            content: this.$t("wallet_transfer_success")
                        });
                        try {
                            setTimeout(() => {
                                this.getInfo();
                                this.fetchNftList();
                            }, 500);
                        } catch (error) {
                            console.log("getInfo", error);
                        }
                    }).catch((err) => {
                        console.log("err", err);
                        this.$message.error({
                            content: err.message || this.$t("wallet_transfer_fail")
                        });
                    }).finally(() => {
                        this.loading = false;
                        this.nftTransferLoading = false;
                    })

                } else {
                    // 好像不会走到这个逻辑？？
                    let params = {
                        requestId: res.data,
                        coin_name: this.coinValue.name,
                        ...code,
                        withdraw_amount: this.curAmount,
                        address: this.curAddress,
                        network_id: this.confirmation.network_id,
                        chain_id: this.chainId,
                        fee_token: this.feeToken
                    };

                    goWithdraw(params).then(
                        (res) => {
                            this.$store.commit("wallet/setWithdrawInfo", {
                                address: this.curAddress,
                                network_id: this.confirmation.network_id,
                                withdraw_amount: this.curAmount,
                                symbol: this.symbol,
                            });
                            this.$router.push({
                                name: "withdrawResult",
                            });
                        },
                        (res) => {
                            this.submitError = res.message;
                        }
                    ).catch(e => e);
                }
            }).catch(e => {
                console.log('transferNft', e)
                this.loading = false;
            })
        },
        resetFee() {
            this.transaction_fee = "";
            this.transaction_fee_value = formatFiatCommify(0, this.coinValue.show_scale);
        },
        setSearchKey() {
            sessionStorage.setItem(
                "searchSymbol",
                this.chainId + "-" + this.coinValue.name
            );
        },

        //nftMethod

		checkChainId() {
			// 链不一致，需要切换链。
            let accountIsCustody = this.accountIsCustody
			const walletChainId = accountIsCustody ? this.$store.state.chainId : this.$store.state.wallet.walletChainId;
			if (walletChainId != this.chainId) {
				this.$bus.$emit("changeBridgeNetWork", this.chainId);
				return false;
			}
			return true;
		},

        async getNftTxFee() {
            this.$store.dispatch("getL2Fee", {
                chainId: this.chainId,
                address: this.account,
                txType: "Transfer"
            }).then(res => {
                this.nftFeeError = false;
                this.nftFee.balance = res.balance;
                this.nftFee.fee = res.totalFee;
                this.nftFee.feeSymbol = res.symbol;

                const tokenDetail = this.$store.getters.commonTokenDetail(this.nftFee.feeSymbol, this.chainId);
                this.nftFee.token = tokenDetail;

                this.fetchTokenPrice(this.nftFee.feeSymbol).then(res => {
                    this.nftFee.price = res[this.currency.name];
                }).catch(e => {
                    this.nftFee.price = null;
                });
            }).catch(e => {
                console.error(e);
                this.nftFeeError = true;
            });
        },

        async fetchTokenPrice(symbol) {
            return getSignleSymbolPrice({
                fsym: symbol,
                tsyms: this.currency.name,
            });
        },
        dealSignatureMessage() {
            let fee = ethers.utils.formatUnits(this.nftFee.fee.toString(), this.nftFee.token?.contract_decimals)
            let signatureMessage =`
                <p>Transfer ${formatFiatCommify(this.nftAmount, this.nftFee.token?.show_scale)} NFT-${this.nftId} to: ${this.curAddress}</p>
                <p>Fee: ${fee} ${this.nftFee.feeSymbol}</p>`
            return signatureMessage
        },
        async transferNFT() {
            if (!this.checkChainId()) return;
            this.nftTransferLoading = true;
            if (!this.curAddress || !this.nftId) {
                this.nftTransferLoading = false;
                return;
            }
            if (this.accountIsCustody)  {
                emitLoading({
                    loading: true
                })
                let signatureMessage = this.dealSignatureMessage()
                let signatureStatue = await this.$bus.$emit('signaturePopup', {
                    title: 'Transfer Nft Notification',
                    message: signatureMessage,
                    walletbalance: this.nftBalanceAmount,
                    symbol: 'NFT-' + this.nftId,
                    chainId: this.chainId,
                    // showPopup: true
                })
                if (!signatureStatue) {
                    let msg = this.$t('user_personal_center_siganature_denied')
                    this.$Message.error({
                        content: msg,
                        duration: this.popTime,
                        closable: true
                    })
                    emitLoading({
                        loading: false
                    })
                    this.nftTransferLoading = false
                    return
                } else {
                    emitLoading({
                        loading: false
                    })
                    this.handleCustodySubmit()
                }
                return
            }
            const syncWallet = this.walletData.getSyncWallet();
            const provider = this.walletData.getProvider();

            const tokenObj = await provider.getNFT(this.nftId);

            console.log(this.nftFee);
            // const tokenObj = accountState.committed.nfts[65558];

            const params = {
                to: this.curAddress,
                token: tokenObj,
                feeToken: this.nftFee.feeSymbol,
                amount: this.nftAmount
                // fee?: BigNumberish;
                // nonce?: Nonce;
                // fastProcessing?: boolean;
                // validFrom?: number;
                // validUntil?: number;
            };

            console.log("params");
            console.log(params);


            const transferResult = await syncWallet.syncTransferNFT(params).catch(error=>{
                const { message } = error;
                let content = this.$t("wallet_transfer_fail");
                if(message.indexOf('The NFT is locked')>-1){
                    content = 'The NFT is locked.'
                }
                this.nftTransferLoading = false;
                this.$message.error({
                    content,
                    duration: 10,
                    closable: true,
                });
            });
            // .catch(error=>{
            //     console.log(error.message)
            // });

            console.log("transferResult");
            console.log(transferResult);


            const [ {txHash:tx1},{txHash:tx2} ] = transferResult;

             if (!transferResult) {
                 this.nftTransferLoading = false;
                this.$message.error({
                    content: this.$t("wallet_transfer_fail"),
                    duration: 10,
                    closable: true,
                });
                return
            }


            if(!tx1||!tx2){
                this.nftTransferLoading = false;
                this.$message.error({
                    content: this.$t("wallet_transfer_fail"),
                    duration: 10,
                    closable: true,
                });
                return
            }



            // const { txHash } = transferResult;

             this.getTxReceipt(tx1)
        },

        async fetchNftList() {
            const res = await fetchNftList({
                page_no: 1,
                page_size: 1000,
                address: this.account,
                chain_layer_list: this.chainId + '-2',
            });
            let nftList = [];
            const { nfts } = res.data;
            for (let i = 0; i < nfts.length; i++) {
                const item = nfts[i];
                if (item.layer === 1) {
                    const nftData = await parseNFTData(item.nft_data);
                    const nftType = Object.prototype.toString.call(nftData);
                    if (nftType === "[object String]") {
                        item.nft_image = nftData;
                    } else if (nftType === "[object Object]") {
                        item.nft_data_object = nftData;
                        item.nft_image = nftData.image;
                    }

                    nftList.push(item);
                }

                if (item.layer === 2) {
                    const { content_hash } = item;
                    console.log(item);
                    const hash = "e30101701220" + content_hash.split("0x")[1];
                    const cid = content_hashUtils.decode(hash);
                    item.ipfsUrl = IPFS_PREVIEW_URL + cid;
                    let ipfsRes = null;
                    try {
                        ipfsRes = await axios.get(item.ipfsUrl).catch();
                        item.nft_data = ipfsRes.data;
                        item.nft_data_object = ipfsRes.data;
                        item.nft_image = item.nft_data_object.image;
                        nftList.push(item);
                    } catch (error) {
                        item.nft_data = {};
                        item.nft_data_object = {};
                        item.nft_image = "";
                        nftList.push(item);
                    }
                }
            }

            this.nftList = nftList;

        },

        async getTxReceipt(txHash) {
            const provider = this.walletData.getProvider();
            const result = await provider.getTxReceipt(txHash);
            console.log(txHash);
            console.log("getTxReceipt");
            console.log(result);

            const { executed,success,failReason} = result;

            if(!executed){
                setTimeout(() =>{
                    this.getTxReceipt(txHash);
                },1000)
                return
            }

            if(!success){
                this.nftTransferLoading = false;
                this.$message.error({
                    content: failReason||this.$t("wallet_transfer_fail"),
                    duration: 10,
                    closable: true,
                });
                return
            }

            this.nftTransferLoading = false;

            this.$message.success({
                content: this.$t("wallet_transfer_success"),
                duration: 10,
                closable: true,
            });


            setTimeout(() => {
                this.nftId = "";
                this.getNftTransferHistory();
                this.fetchNftList();
            },1000)
        },

        selectNft() {
        },
        onChangeNftChain(item) {
            this.nftList = [];
            this.fetchNftList();
            this.getNftTxFee();
            this.getNftTransferHistory();
        },
    },
    beforeDestroy() {
        sessionStorage.removeItem("searchSymbol");
    },
};
